import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Modal } from '@mui/material';

import useSubscription from '@/hooks/account/useSubscription';
import { PromoteFlowQueryParams } from '@/models/Enums';

import ButtonComponent from '../utility/microcomponents/Button';
import CustomIcons from '../utility/microcomponents/CustomIcons';
import SubscriptionBanner from './SubscriptionBanner';
import SubscriptionPerksAndPaymentPopup from './SubscriptionPerksAndPaymentPopup';

const Subscription = ({ type, element }: { type: string; element?: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [params, setSearchParams] = useSearchParams();

  const { isSubscribed } = useSubscription();

  const removeParams = useCallback(() => {
    params.delete('subscription');
    params.delete(PromoteFlowQueryParams.FAN_HUB);
    setSearchParams(params);
  }, [params, setSearchParams]);

  const handleModalClose = useCallback(() => {
    setOpen(false);
    removeParams();
  }, [removeParams]);

  useEffect(() => {
    if (isSubscribed && open) {
      handleModalClose();
    }
  }, [handleModalClose, isSubscribed, open]);

  useEffect(() => {
    if (params.get('subscription') === 'true') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [params]);

  return (
    <>
      <Modal open={open} onClose={handleModalClose}>
        <>
          <SubscriptionPerksAndPaymentPopup
            closeModalOutput={() => {
              handleModalClose();
            }}
          />
        </>
      </Modal>

      {!isSubscribed && (
        <>
          {type === 'banner' && (
            <div
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
            >
              <SubscriptionBanner />
            </div>
          )}
          {type === 'pill' && (
            <div
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
            >
              <ButtonComponent isCustom className="m0 p0">
                <div className="m0 br32 subscription-banner text-white pt8 pb8 pl16 pr16 border-purple d-flex min-w88">
                  <CustomIcons className="pt2 text-purple" name="crown" />
                  <h5 className="text-purple pl8">Join pro</h5>
                </div>
              </ButtonComponent>
            </div>
          )}
          {type === 'banner-small' && (
            <div
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
            >
              <SubscriptionBanner size="small" />
            </div>
          )}
          {type === 'settings' && (
            <div
              onClick={() => {
                setOpen(true);
              }}
            >
              Settings
            </div>
          )}
          {type === 'playlisting' && (
            <div
              onClick={() => {
                setOpen(true);
              }}
            >
              Playlisting
            </div>
          )}
        </>
      )}
      {type === 'element' && element && (
        <ButtonComponent
          isCustom
          className="cursor-pointer w100p p0"
          onClick={() => {
            setOpen(true);
          }}
        >
          {element}
        </ButtonComponent>
      )}
    </>
  );
};

export default Subscription;
